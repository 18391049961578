<template>
  <div class="inside-os report-os">
    <div class="com-title">
      <span>报表查询>>{{getQuery.currentDate|substrSome}}>>{{getQuery.namecn}}</span>
      <span class="gobackspan" @click="$router.go(-1)">返回上一页</span>
    </div>
    <div class="report">
      <table class="com-table">
        <tr>
          <th style="width:158px">单号</th>
          <th style="width:120px">下注时间</th>
          <th style="width:150px">类型</th>
          <th>玩法</th>
          <th v-if="list[0].codeArr" style="width:180px">开奖结果</th>  
          <th width="30">盘口</th>
          <th width="80px">赔率</th>
          <th width="80">金额</th>
          <th width="80">退水</th>
          <th width="100">结果</th>
        </tr>
        <tr v-for="item in list" :key="item.orderid ">
          <td><a href="javascript:;" class="normal_a" :class="item.state==3?'chedan':''"> {{item.orderid}}</a></td>
          <td>{{item.optime|substrSome}}</td>
          <td>{{item.roomcn}}<br>
            <span class="green">第{{item.expect}}期</span>
          </td>
          <td> <span class="blue">{{item.iteminfo}} {{item.ordernum}}</span> </td>
          <td class="pcddopen yijie" v-if="item.codeArr">
            <template v-if="item.settletype=='pcdd'">
              <div style="display: flex;"> 
              <template v-for="(n, index) in item.codeArr">
                  <p
                    :class="'ball b' + Number(index)"
                    v-if="index < item.codeArr.length - 1"
                    :key="n + index"   
                  >{{ n }}</p>
                  <p
                    :class="pcddColors[Number(n)]+'ball1'"
                    v-if="index == item.codeArr.length - 1"
                    :key="n + index" style="margin-left:10px; "
                  >{{n}}</p>
                </template>
              </div>
            </template>
          </td>
          <td>{{item.pan}}</td>
          <td> <span class="red">{{item.winodds}}</span> </td>
          <td>{{item.amount}}</td>
          <td>{{item.tuishui}}</td>
          <td :class="Number(item.shuying)>0?'cublue':'red'">{{item.shuying.toFixed(2)}}</td>
        </tr>
        <tr v-if="list.length>0">
          <th>合计</th>
          <th :colspan="list[0].codeArr?'6':'5'">{{list.length}}</th>
          <th>{{tAmount.toFixed(2)}}</th>
          <th>{{tTuishui.toFixed(2)}}</th>
          <th :class="Number(tShuying)>0?'cublue':'red'">{{Number(tShuying).toFixed(2)}}</th>
        </tr>
        <tr v-if="list.length==0">
          <td :colspan="list[0].codeArr?'10':'9'" class="ctr">没有数据...</td>
        </tr>
      </table>

    </div>
    <div class="page">
      <el-pagination :page-sizes="[20,50,100,200]" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="sizes,total,  prev, pager, next, jumper" :total="totals">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      currentDate: "",
      gamecn: "",
      // 合计
      tAmount: 0,
      tTuishui: 0,
      tShuying: 0,
      // 条查
      queryParam: {},
      // state(订单状态:0 未结算 1 已结算 3 系统退单 4 用户撤单 -1 全部)
      state: -1,
      pages: {
        pagenum: 1,
        pagecount: 20,
      },
      totalPage: 1,
      totals: 1,
      list: [],
      getQuery: {},
    };
  },

  components: {},
  created() {
    console.log(this.userInfo);
    console.log(this.$route.query);
    this.getQuery = this.$route.query;
    this.getList();
  },
  computed: {
    ...mapState(["userInfo"]),
  },

  methods: {
    getList() {
      this.tAmount = 0;
      this.tTuishui = 0;
      this.tShuying = 0;

      let obj = {
        uuid: this.userInfo.Uuid,
        // uuid: 10002012,
        sid: this.userInfo.Sid,
        state: this.state,
        pagenum: this.pages.pagenum,
        pagecount: this.pages.pagecount,
        begindate: this.getQuery.currentDate,
        enddate: this.getQuery.currentDate,
        roomeng: this.getQuery.nameeng,
      };
      this.$http
        .post("getlotorderinfos", obj)
        .then((res) => {
          if (res.Status) {
            if (res.Msg) {
              let resObj = JSON.parse(res.Msg);
              // this.list = resData;
              this.list = resObj.data;
              this.list.forEach((item) => {
                if(item.opencode&&item.settletype=='pcdd'){
                  item.codeArr=item.opencode.split(',')
                  let sum=0;
                  item.codeArr.forEach(ele=>{
                    sum+=Number(ele)
                  })
                  item.codeArr.push(sum)
                }
                this.tAmount += Number(item.amount);
                this.tTuishui += Number(item.tuishui);
                this.tShuying += Number(item.shuying);
              });
              this.totalPage = resObj.maxpagenum;
              this.totals = resObj.maxrecordnum;
              console.log(this.list,124);
            }
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {});
    },
    splitDate(time) {
      let arr = time.split("-");
      let str = "";
      arr.forEach((element) => {
        str += element;
      });
      return str;
    },
    handleSizeChange(val) {
      console.log(val);
      this.pages.pagecount = val;
      this.pages.pagenum = 1;
      this.getList();
    },
    // 当前页面
    handleCurrentChange(page) {
      this.pages.pagenum = page;
      this.getList();
    },
  },
};
</script>
<style  scoped>
.green {
  color: green;
}
.blue {
  color: blue;
}
.red {
  color: red;
  font-weight: bold;
}
.cublue {
  color: blue;
  font-weight: bold;
}
td {
  padding: 0;
  text-align: center;
  vertical-align: middle;
}
.inside-os.report-os .com-title a {
  font-weight: 400;
  color: #4f4d4d;
}
.inside-os.report-os .report .com-table.rt-table {
  border-top: none;
}
a {
  text-decoration: none;
}
.inside-os.report-os .report .com-table td.win,
.inside-os.report-os .report .com-table td.win a {
  color: #2836f4;
}
.inside-os.report-os .report .com-table td.ctr {
  padding: 4px 0;
  text-align: center;
}

.inside-os.report-os .report .com-table td {
  /* padding: 4px 10px 4px 0; */
  text-align: center;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.inside-os .com-table {
  width: 100%;
}
.inside-os.report-os .report {
  margin-top: 5px;
}
.inside-os.report-os .com-title {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.inside-os .com-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 34px;
  font-size: 14px;
  font-weight: 700;
}
/* .inside-os {
 min-width: 1018px;
  padding: 0 0 10px 10px;
  color: #4f4d4d;
} */
</style>